import * as types from "../types";
import axios from "axios";
import { serverAPI } from "../../utils/envConfig";
import { hasCookie, getCookie } from "cookies-next";

export const addNewsletter = (params) => async (dispatch) => {
  const headers = {
    "Content-Type": "application/json",
  };
  try {
    const res = await axios.post(`${serverAPI}/newsletter`, params, {
      headers,
    });
    // console.log(res.data);
    dispatch({
      type: types.POST_NEWSLETTER,
      payload: res.data,
    });
  } catch (error) {
    // console.log(error);
  }
};
